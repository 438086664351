<template>
  <div class="">
    <main>
      <slot />
    </main>

    <footer class="bg-baby-powder text-center flex justify-center items-center lg:text-lg h-16">
      <p class="font-bold">
        Giving Together Is Better <span class="font-medium">| </span>
        <a
          href="https://dollardonationclub.com"
          target="_blank"
          rel="noreferrer noopener"
          class="font-NeueHaasUnicaPro font-normal -tracking-[0.02em] underlinefix"
          >Dollar Donation Club</a
        >
      </p>
    </footer>
  </div>
</template>

<style lang="scss" scoped>
.underlinefix {
  text-decoration: underline;
  text-decoration-thickness: 1px;
  text-underline-offset: 4px;
  @media (min-width: 1024px) {
    text-underline-offset: 8px;
  }
}
</style>
